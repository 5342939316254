import { mapActions, mapGetters, mapState } from "vuex";
import get from "lodash/get";
import split from "lodash/split";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      category: ({ category }) => category.category
    }),
    ...mapGetters({
      breadcrumb: "category/categoryPath",
      promoFilter: "category/promoFilter"
    })
    // computed: {
    //   canonicalUrl() {
    //     return `https://${window.location.host}/${this.category.slug}`;
    //   }
    // }
  },
  methods: {
    //deprecated -> use proposal mapper in ebsn.js
    getProposals(name) {
      return get(this.category, "proposals." + name, []);
    },
    ...mapActions({
      setCategory: "category/setCategory",
      resetFilters: "category/resetFilters"
    })
  },
  metaInfo() {
    if (this.category) {
      return {
        title: get(
          this.category,
          "metaData.category_seo.SEO_TITLE",
          split(this.category.name, "@")[0]
        ),
        link: [
          {
            rel: "canonical",
            href: `https://${window.location.host}${this.$route.meta
              .canonical || "/" + this.category.slug}`
          }
        ],
        meta: [
          {
            vmid: "description",
            name: "description",
            content: get(
              this.category,
              "metaData.category_seo.SEO_DESCRIPTION",
              this.category.description
            )
          },
          // Open Graph Metas
          {
            property: "og:locale",
            content: "it_IT"
          },
          {
            property: "og:title",
            content: get(
              this.category,
              "metaData.category_seo.SEO_TITLE",
              split(this.category.name, "@")[0]
            )
          },
          {
            property: "og:type",
            content: "product.group"
          },
          {
            property: "og:url",
            content: location.href
          },
          {
            property: "og:image",
            content:
              "https://" + window.location.host + global.config.socialLogo ||
              "/logo/social-logo.png"
          },
          {
            property: "og:image:alt",
            content: get(
              this.category,
              "metaData.category_seo.SEO_DESCRIPTION",
              this.category.description
            )
          },
          // Twitter Metas
          {
            name: "twitter:card",
            content: "summary_large_image"
          },
          {
            name: "twitter:title",
            content: get(
              this.category,
              "metaData.category_seo.SEO_TITLE",
              split(this.category.name, "@")[0]
            )
          },
          {
            name: "twitter:image",
            content:
              "https://" + window.location.host + global.config.socialLogo ||
              "/logo/social-logo.png"
          },
          {
            name: "twitter:url",
            content: location.href
          }
        ]
      };
    } else {
      return {
        title: "Pagina non trovata"
      };
    }
  }
};
